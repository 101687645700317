<template lang="pug">
include ../pug/svg
main#album
	div#preview(v-show="album")
		div
			div(v-if="album")
				div.c1.blurb
					picture(v-if="album.artistPhoto")
						img(:src="album.artistPhoto" :alt="album.artistName")
					hgroup
						h2 {{album.title}}
						h3 {{album.artistName}}
						ul(v-if="album.albumArtists")
							li(v-if="album.albumArtists[0]?.facebook")
								a.facebook(:href="facebookUrl(album.albumArtists[0])" target="_blank") Facebook
									+svg(svg-filename="iconFacebook")
							li(v-if="album.albumArtists[0]?.twitter")
								a.twitter(:href="twitterUrl(album.albumArtists[0])" target="_blank") Twitter
									+svg(svg-filename="iconTwitter")
					div(v-html="album.description")
					dl(v-if="album.estPreorder||album.estShipping")
						dt(v-if="album.estPreorder") Pre-order Date
						dd(v-if="album.estPreorder") {{album.estPreorder}}
						dt(v-if="album.estShipping") Est. Shipping Date
						dd(v-if="album.estShipping") {{album.estShipping}}
				div.c2
					div.artwork(:class="{flipped:flipArtwork===true,reflipped:flipArtwork===false}")
						span.sku {{album.trrSku}} / {{album.catNum}}
						div
							div.album.front
								em(v-if="album.state === 'soon'") Coming Soon&hellip;
								em(v-if="!album.stock && album.state !== 'soon'") Out of Stock
								em(v-if="album.stock && album.state === 'stock'") In Stock 
									//-strong {{ album.stock }}
								em(v-if="album.stock && album.state === 'preorder'") Pre-Order
									//-strong {{ album.stock }}
								em(v-if="album.stock && album.state === 'funding'") Funded
									//-strong {{ album.stock }}
								picture
									img(:src="album.artworkCover" :alt="'Album front cover - '+album.artistName+', '+album.title")
								button.icon.pulse.flip(type="button" v-if="album.artworkBack" @click="toggleFlip()" @mouseover="cancelPreviewHint=true" :class="{hint:!cancelPreviewHint}")
									+svg(svg-filename="iconFlip")
							div.album.back
								em(v-if="album.state === 'soon'") Coming Soon&hellip;
								em(v-if="!album.stock && album.state !== 'soon'") Out of Stock
								em(v-if="album.stock && album.state === 'stock'") In Stock 
									//-strong {{ album.stock }}
								em(v-if="album.stock && album.state === 'preorder'") Pre-Order
									//-strong {{ album.stock }}
								em(v-if="album.stock && album.state === 'funding'") Funded
									//-strong {{ album.stock }}
								picture
									img(:src="album.artworkBack" :alt="'Album back cover - '+album.artistName+', '+album.title")
								button.icon.pulse.flip(type="button" @click="toggleFlip()")
									+svg(svg-filename="iconFlip")
					ul.toolbar
						li.but-price
							Price(:price="vatPrice(this.album.price)" vat="inc")
							button.text.pulse(type="button" @click="addAlbumToBasket(album)" :disabled="!album.mintsoftProductId || !album.stock || album.state === 'soon'") Buy Now
						li(v-if="hasAudio")
							button.icon.pulse(type="button" @click="togglePlay()" @mouseover="cancelPreviewHint=true" :class="{pulsate:previewTrack,hint:!cancelPreviewHint}")
								+svg(svg-filename="iconListen")
								span Listen
				div.c3.tracklist
					div.selector(v-show="trackList.length")
						div
							h2#recordH2(aria-label="Select record number") Record
							ul#record(aria-labelledby="recordH2")
								li(v-for="(num, index) in recordNumbers" :key="index")
									button(type="button" aria-label="Record {{num}}" :class="{on: num == selectedRecord}" @click="swapRecord(num)") {{num}}
						div
							h2#sideH2(aria-label="Select record side") Side
							ul#side(aria-labelledby="sideH2")
								li(v-for="(side, index) in recordSides" :key="index")
									button(type="button" aria-label="{{side}} Side" :class="{on: side == selectedSide}" @click="swapSide(side)") {{side}}
					ol.tracks(:class="{swap:swapTrack}")
							li.track(v-show="inTrackList(track.id)" v-for="(track, index) in album.albumTracks" :key="track.id" aria-label="Record {{track.record}} {{track.side}} side track {{index}}")
								TrackPlayer(:track="track" :album="album" @dataFromTrackPlayer="updatePlaying")
</template>

<script>
import Price from '../components/Price';
import TrackPlayer from '../components/TrackPlayer';
import BasketButton from '../components/BasketButton';
import PaymentService from '../services/PaymentService';

// album service needed for valid slug check
//import AlbumService from '../services/AlbumService.js';

export default {
	name: 'AlbumPreview',
	emits: ['addedToBasket'],
	components: {
		Price,
		TrackPlayer,
		BasketButton,
	},
	data() {
		return {
			pandp: [],
			previewTrack: null,
			selectedRecord: 1, 
			selectedSide: 'a',
			flipArtwork: null, // null is inportant
			cancelPreviewHint: false,
			swapAlbum: false,
			swapTrack: false,
		};
	},
	async created() {
		const {data} = await PaymentService.getDelivery();
		this.pandp = data;
	},		
	mounted() {
		this.loadAlbum();
	},
	methods: {
		async loadAlbum() {	
			// load albums from database
			await this.$store.dispatch('albums/dbAllAlbums');
			
			// decrement stock quantities if in basket
			this.$store.dispatch('basket/initiateStock');
		},
		facebookUrl(artist) {
			return (artist.facebook) ? 'https://www.facebook.com/' + artist.facebook : null;
		},
		twitterUrl(artist) {
			return (artist.twitter) ? 'https://twitter.com/' + artist.twitter : null;
		},
		vatCharge(price) {
			let vat = (price * this.pandp.vatPct);
			return Math.round((vat + Number.EPSILON) * 100) / 100;
		},
		vatPrice(price) {
			return (price + this.vatCharge(price)).toFixed(2);
		},		
		toggleFlip() {
			this.flipArtwork = !this.flipArtwork;
		},
		togglePlay() {
			if (this.previewTrack) {
				this.previewTrack.playing = false;	
				this.previewTrack = null;
				
			} else {
				this.swapRecord(1);
				this.swapSide('a');
				this.previewTrack = this.album.albumTracks[0];				
				this.previewTrack.playing = true;	
			}
		},
		updatePlaying(trackId, isPlaying) {
			// triggered from TrackPlayer via $emit
			for (let track of this.album.albumTracks) {
				if (isPlaying) {
					// track started
					if (track.id === trackId) {
						this.previewTrack = track;
						this.swapSide(track.side);
						this.swapRecord(track.record);
						track.playing = true;
						
					} else {
						// stop other tracks playing 
						track.playing = false;
					}					
					
				} else {
					// track ended
					if (track.id === trackId) {
						this.previewTrack = null;
						track.playing = false;						
						
					} else {
						// play next track
						if (!this.previewTrack) {
							this.previewTrack = track;						
							this.swapSide(track.side);
							this.swapRecord(track.record);
							track.playing = true;
						}
					}
				}
			}
		},
		swapRecord(record) {
			if (record === this.selectedRecord) {
				return;
			}
			this.swapTrack = true;
			this.swapSide('a');
			
			setTimeout(function() { 
				this.selectedRecord = record;
			}.bind(this), 300);
			
			setTimeout(function() { 
				this.swapTrack = false;
			}.bind(this), 600);
		},
		swapSide(side) {
			if (side === this.selectedSide) {
				return;
			}
			
			this.swapTrack = true;
			
			setTimeout(function() { 
				this.selectedSide = side;
			}.bind(this), 300);
			
			setTimeout(function() { 
				this.swapTrack = false;
			}.bind(this), 600);
		},
		addAlbumToBasket(album) {
			this.$store.dispatch('basket/addToBasket', {item: album, type: 'album'});
			// notify App.vue
			this.$emit('addedToBasket');			
		},
		inTrackList(trackId) {
			const track = this.trackList.find(t => t.id === trackId);
			return (track) ? true : false;
		},
	},
	computed: {
		album() {
			return this.$store.getters['albums/albumBySlug'](this.$route.params.slug);
		},
		hasAudio() {
			const audios = (this.album?.albumTracks) ? this.album.albumTracks.filter(t => { return t.file !== null }) : null;
			return audios.length ? true : false; 
		},
		trackList() {
			// return tracks for selected record side
			var tracks = this.album.albumTracks.filter(track => {
				return (track.side === this.selectedSide && track.record === this.selectedRecord);
			});
			
			return tracks;	
		},
		recordSides() {
			// return all sides
			const sides = this.album.albumTracks.map(({ side }) => side);
			// make unique
			return new Set(sides);
		},
		recordNumbers() {
			// get all record numbers
			const records = this.album.albumTracks.map(({ record }) => record);
			// make unique
			return new Set(records);
		},
	},
};
</script>

<style lang="scss">
#album {
	padding: 0 40px 80px 40px;		
}
#preview {
	>div {
		display: flex;
		max-width: 1400px;
		margin: auto;
		>div {
			display: flex;
			width: 100%;		
		}
	}
	.c2 {
		position: relative;
		padding-top: 20px;		
	}
	@media only screen and (max-width: 939px) {
		.c1 {
			padding: 60px 0 20px 0;
			order: 1;
		}
		>div {
			>div {
				flex-direction: column;
				max-width: 500px;
				margin: auto;		
			}
		}
	}
	@media only screen and (min-width: 940px) {
		>div {
			>div {
				justify-content: space-between;
				>* {
					width: 33.33%;
				}
			}
		}
		.c1 {
			padding-top: 20px;
		}
		.c2 {
			flex-grow: 1;
			margin: 0 40px;
			max-width: 680px;			
		}
	}
}
</style>